<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      absolute
      fixed
      color="transparent"
      elevation="0"
      height="60"
    >
      <v-spacer />
      <a
        href="/"
        :style="$vuetify.breakpoint.xs ? 'position: absolute; left: 15px;' : 'position: absolute; left: 18%;'"
      >
        <img
          :src="require('@/assets/Lavvira_logo_SVG.svg')"
          class="mr-3 mt-1"
          style="min-width: 150px;"
        >
      </a>
      <div
        class="row hidden-xs-only"
        style="position: absolute; left: 35%;"
      >
        <div
          style="font-size: 15px; letter-spacing: 1px; cursor: pointer;"
          class="font-weight-bold hover"
          @click="scrollToLavviraFeaturesSection()"
        >
          Features
        </div>
        <div
          style="font-size: 15px; letter-spacing: 1px;"
          class="font-weight-bold ml-4 hover"
          @click="scrollToPricingSection()"
        >
          Pricing
        </div>
        <div
          style="font-size: 15px; letter-spacing: 1px;"
          class="font-weight-bold ml-4 hover"
          @click="goToCalendar"
        >
          Request a demo
        </div>
        <!-- <v-menu
          v-model="menu"
          offset-y
          open-on-hover
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              style="font-size: 15px; letter-spacing: 1px;"
              class="font-weight-bold"
              :class="menu ? 'font-weight-bold activeFeatures' : 'features'"
              v-on="on"
              @click="scrollToLavviraFeaturesSection()"
            >
              Features
            </div>
          </template> -->
        <!-- <v-card
            min-width="900"
            class="mx-auto"
          >
            <v-list class="pb-4">
              <v-row>
                <v-col cols="6">
                  <v-subheader style="font-size: 18px;">
                    Core features
                  </v-subheader>
                  <v-list-item
                    v-for="(item, index) in features"
                    :key="index"
                    two-line
                  >
                    <v-icon
                      color="primary"
                    >
                      {{ item.icon }}
                    </v-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        class="ml-4"
                      >
                        {{ item.title }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="ml-4"
                      >
                        {{ item.subtitle }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="6">
                  <v-subheader style="font-size: 18px;">
                    Advanced features
                  </v-subheader>
                  <v-list-item
                    v-for="(item, index) in advancedFeatures"
                    :key="index"
                    two-line
                  >
                    <v-icon
                      color="primary"
                    >
                      {{ item.icon }}
                    </v-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        class="ml-4"
                      >
                        {{ item.title }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="ml-4"
                      >
                        {{ item.subtitle }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-list>
          </v-card> -->
        <!-- </v-menu> -->
        <!-- <div
          style="font-size: 15px; letter-spacing: 1px;"
          class="font-weight-bold ml-4 hover"
        >
          Company
        </div>
        <div
          style="font-size: 15px; letter-spacing: 1px;"
          class="font-weight-bold ml-4 hover"
        >
          Team
        </div> -->
      </div>

      <v-spacer />

      <v-btn
        v-if="$vuetify.breakpoint.mdAndUp"
        min-height="35"
        min-width="150"
        rounded
        small
        outlined
        class="mr-4 mt-2 pl-3"
        color="white"
        href="https://app.lavvira.net"
      >
        log in
        <v-icon
          small
          class="ml-1"
        >
          mdi-login
        </v-icon>
      </v-btn>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
      @navigate="scrollToMobileSection"
    />
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },

    data: () => ({
      drawer: null,
      menu: false,
      items: [
        {
          text: 'Home',
          icon: 'mdi-home-outline',
        },
        {
          text: 'Features',
          icon: 'mdi-feature-search-outline',
        },
        {
          text: 'Pricing',
          icon: 'mdi-label-outline',
        },
        {
          text: 'Request a demo',
          icon: 'mdi-presentation',
        },
        // {
        //   text: 'Company',
        //   icon: 'mdi-domain',
        // },
        // {
        //   text: 'Team',
        //   icon: 'mdi-account-supervisor-circle',
        // },
      ],
      link: 'https://app.lavvira.net',
      features: [
        {
          title: 'Smart template builder',
          subtitle: 'Turn any photo or scan automatically into a template.',
          icon: 'mdi-text-box-outline',
        },
        {
          title: 'Data extraction',
          subtitle: 'Get relevant data from any document.',
          icon: 'mdi-database-cog-outline',
        },
        {
          title: 'Smart data filling',
          subtitle: 'Enter seamless already extracted data.',
          icon: 'mdi-file-document-edit-outline',
        },
        {
          title: 'Case management',
          subtitle: 'Manage and work on your cases from wherever you want.',
          icon: 'mdi-briefcase-variant-outline',
        },
        {
          title: 'Document management',
          subtitle: 'Upload, download and access documents from anywhere.',
          icon: 'mdi-file-document-outline',
        },
        {
          title: 'Integrated questionnaire',
          subtitle: 'Clients data is available direct in your templates.',
          icon: 'mdi-message-question-outline',
        },
        {
          title: 'Integration and customer management',
          subtitle: 'Receive and share documents and information with your customers effortlessly and secure.',
          icon: 'mdi-account-sync-outline',
        },
      ],
      advancedFeatures: [
        {
          title: 'Company and group management',
          subtitle: 'lavvira is office, company and business ready.',
          icon: 'mdi-account-group-outline',
        },
        {
          title: 'Case models',
          subtitle: 'Turn your cases in to case models and reuse them when needed.',
          icon: 'mdi-briefcase-edit-outline',
        },
        {
          title: 'Collaboration',
          subtitle: 'Co-working on cases, case models and templates based on permissions',
          icon: 'mdi-account-network-outline',
        },
      ],
    }),
    methods: {
      goToCalendar () {
        if (this.$route && this.$route.name !== 'Home') {
          this.$router.push('/')
          setTimeout(() => {
            const element = document.getElementById('lavvira-demo-and-contact')
            element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
          }, 1000)
        } else {
          const element = document.getElementById('lavvira-demo-and-contact')
          element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        }
      },
      scrollToLavviraFeaturesSection () {
        if (this.$route && this.$route.name !== 'Home') {
          this.$router.push('/')
          setTimeout(() => {
            const element = document.getElementById('lavvira-features')
            element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
          }, 1000)
        } else {
          const element = document.getElementById('lavvira-features')
          element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        }
      },
      scrollToPricingSection () {
        if (this.$route && this.$route.name !== 'Home') {
          this.$router.push('/')
          setTimeout(() => {
            const element = document.getElementById('pricing')
            element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
          }, 1000)
        } else {
          const element = document.getElementById('pricing')
          element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        }
      },
      scrollToMobileSection (item) {
        if (item.text === 'Features') this.scrollToLavviraFeaturesSection(); this.drawer = false
        if (item.text === 'Pricing') this.scrollToPricingSection(); this.drawer = false
        if (item.text === 'Request a demo') this.goToCalendar(); this.drawer = false
      },
    },
  }
</script>

<style scoped>
.features {
  color: white;
}
.activeFeatures {
 color: #ecb652;
}
.hover {
  color: white;
}
.hover:hover {
  color: #ecb652;
  cursor: pointer;
}
</style>
